<!-- Блок с задачами. -->
<div class="d-flex">
  <div class="d-flex mt-3 col-lg-3">
    <div class="block-worksheet col-lg-12">
      <div class="d-flex block-task">
        <div class="task-ver-line">
          <p-menu [model]="items"></p-menu>
        </div>
      </div>
    </div>
  </div>

  <!-- Создание метки (тега). -->
  <div class="block-create-tag col-lg-9 mt-3" *ngIf="isShowCreateTag">
    <h3>Создание новой/нового метки (тега)</h3>
    <div class="tag">
      <div>
        <span class="ml-2">Название метки (тега)</span>
      </div>

      <div>
        <input type="text" class="p-inputtext-sm" pInputText [(ngModel)]="tagName">
      </div>

      <div>
        <span class="ml-2">Описание метки (тега) - опционально</span>
      </div>

      <div>
        <input type="text" class="p-inputtext-sm" pInputText [(ngModel)]="tagDescription">
      </div>

      <div class="mt-3">
        <p-button label="Создать" 
                  styleClass="p-button-sm" 
                  [disabled]="tagName == ''"
                  (click)="onCreateUserTaskTagAsync()">
        </p-button>
      </div>
    </div>
  </div>

  <!-- Создание статуса. -->
  <div class="block-create-status col-lg-9 mt-3" *ngIf="isShowCreateStatus">
    <h3>Создание нового статуса</h3>
    <div class="status">
      <div class="tag">
        <div>
          <span class="ml-2">Название статуса</span>
        </div>
  
        <div>
          <input type="text" class="p-inputtext-sm" pInputText [(ngModel)]="statusName">
        </div>

        <div>
          <span class="ml-2">Описание статуса - опционально</span>
        </div>
  
        <div>
          <input type="text" class="p-inputtext-sm" pInputText [(ngModel)]="statusDescription">
        </div>

        <div>
          <label class="control-label-task-item">Категория</label>
        </div>
        <div>
          <p-dropdown [options]="templateStatuses$.value" 
                    [(ngModel)]="selectedAssociationStatusSysName" 
                    optionLabel="statusName" 
                    [showClear]="true" 
                    placeholder="Выберите статус"
                    [emptyMessage]="(templateStatuses$ | async) === true ? 'Результатов не найдено' : 'Загрузка...'"
                    (onClick)="onGetProjectTemplateStatusesAsync()">
        </p-dropdown>
        <br>
        <small>С каким из статусов будет ассоциироваться новый статус.</small>
        </div>
  
        <div class="mt-3">
          <p-button label="Создать" 
                    styleClass="p-button-sm" 
                    [disabled]="statusName == ''"
                    (click)="onCreateUserTaskStatusTemplateAsync()">
          </p-button>
        </div>
      </div>
    </div>
  </div>
</div>