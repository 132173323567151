<nav class="navbar navbar-light header-line">
  <a class="navbar-brand" routerLink="">
    <img class="logo" src="../../../../assets/images/logo/logo.svg">
  </a>

  <p-menubar *ngIf="isShowLandingMenu" [model]="headerLandingData$.value?.items" />
  <p-menubar *ngIf="isHideAuthButtons" [model]="headerData$.value?.items" />

  <div class="header-buttons d-flex align-right" *ngIf="!isHideAuthButtons">
    <div class="menu-item">
      @if (currentUrl!='/user/signin') {
      <button class="btn btn-primary btn-header button-action-auth-control sign-button"
              (click)="onRouteSignIn()">Войти
      </button>
      } @else {
      <button class="btn btn-primary btn-header button-action-auth-control sign-button"
            (click)="onRouteSignUp()">Регистрация
      </button>
      }
    </div>
  </div>

<!-- <div class="user-avatar d-flex justify-content-end" *ngIf="isHideAuthButtons">-->
<!--  <p-avatar label="P"-->
<!--            shape="circle"-->
<!--            (click)="menu.toggle($event)">-->
<!--  </p-avatar>-->

<!--  <p-menu #menu [popup]="true" [model]="items" appendTo="body"></p-menu>-->
<!-- </div>-->
</nav>
