<div class="footer">
  <div class="footer-wrapper">
    <div class="footer-top">
      <div class="footer-logo">
        <a routerLink="/">
          <img src="/assets/images/logo/logo.svg" />
        </a>
      </div>

      <div class="footer-menu">
        <div class="footer-menu-column">
          <h4 class="footer-menu-section-header">Сервисы</h4>
          <nav class="footer-nav">
            <ul class="footer-menu-list">
              @for (menuItem of menuItems.services; track $index) {
              <li>
                <a class="footer-menu-item" [routerLink]="[menuItem.link]">
                  <span>{{ menuItem.label }}</span>
                </a>
              </li>
              }
            </ul>
          </nav>
        </div>

        <div class="footer-menu-column">
          <h4 class="footer-menu-section-header">Информация</h4>
          <nav class="footer-nav">
            <ul class="footer-menu-list">
              @for (menuItem of menuItems.info; track $index) {
              <li>
                <a class="footer-menu-item" [routerLink]="[menuItem.link]">
                  <span>{{ menuItem.label }}</span>
                </a>
              </li>
              }
            </ul>
          </nav>
        </div>

        <div class="footer-menu-column">
          <h4 class="footer-menu-section-header">Поддержка</h4>
          <nav class="footer-nav">
            <ul class="footer-menu-list">
              @for (menuItem of menuItems.support; track $index) {
              <li>
                <a class="footer-menu-item" [routerLink]="[menuItem.link]">
                  <span>{{ menuItem.label }}</span>
                </a>
              </li>
              }
            </ul>
          </nav>
        </div>

        <div class="footer-menu-column">
          <h4 class="footer-menu-section-header">Мы в социальных сетях</h4>
          <nav class="footer-nav">
            <ul class="footer-social-list">
              @for (menuItem of menuItems.social; track $index) {
              <li>
                <a class="footer-menu-item" [href]="[menuItem.link]">
                  <img [src]="menuItem.imgSrc" [alt]="menuItem.label" class="social-logo" />
                </a>
              </li>
              }
            </ul>
          </nav>
        </div>

      </div>
    </div>

    <div class="footer-bottom">
      <div class="footer-paysys">
        <img src="/assets/images/logo/visa.png" alt="VISA" class="paysys-logo" />
        <img src="/assets/images/logo/mastercard.png" alt="Mastercard" class="paysys-logo" />
        <img src="/assets/images/logo/mir.png" alt="МИР" class="paysys-logo" />
      </div>
      <div class="footer-copyright">
        <span>© 2023-{{ currentDate | date: 'yyyy' }} Leoka Estetica. Сделано с заботой.</span>
      </div>
    </div>
  </div>
</div>
