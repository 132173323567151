<p-toast></p-toast>

<div class="d-flex block-profile space-top-80">
  <div class="profile-left-menu col-lg-3">

  </div>

  <div class="block-worksheet">
    <div class="worksheet-panel">
      <p-panel header="Данные заказа" [toggleable]="true">
        <div class="d-flex">
            <div class="control-input-text-worksheet">
              <div>
                  <strong>ID:</strong>
              </div>
              <div>
                 <p>{{orderId}}</p>
              </div>          
            </div>         
          </div>

        <div class="d-flex">
          <div class="control-input-text-worksheet">
            <div>
                <label>Название тарифа:</label>
            </div>
            <div>
               <p>{{orderName}}</p>
            </div>          
          </div>         
        </div>

        <div class="d-flex">
            <div class="control-input-text-worksheet">
                <div class="control-input-text-worksheet">
                    <div class="d-flex worksheet-line">
                        <div class="control-input-text-worksheet">
                            <div>
                                <label>Описание заказа</label>
                            </div>
                            <div>
                                <p>{{orderDetails}}</p>
                            </div>
                        </div>                         
                    </div>
                  </div>
              </div>
        </div>

        <div class="d-flex">
          <div class="control-input-text-worksheet">
            <div>
                <label>Сумма заказа:</label>
            </div>
            <div>
                <p>{{price + ' ₽'}}</p>
            </div>          
          </div>         
        </div>

        <div class="d-flex">
            <div class="control-input-text-worksheet">
              <div>
                  <label>Статус заказа:</label>
              </div>
              <div>
                  <p>{{statusName}}</p>
              </div>          
            </div>         
          </div>
      </p-panel>
    </div>

    <!-- <div class="d-flex worksheet-line mt-3">
      <div class="control-input-text-worksheet">
        <button type="button" class="btn btn-primary button-action-pur-control-worksheet"
          (click)="onCreateProjectAsync()">
          Сохранить
        </button>
      </div>
    </div> -->
  </div>
</div>