<p-toast></p-toast>

<div class="left-panel" (mouseover)="onSelectPanelMenu()">
  <div style="border-left:1px solid #C0C6CF;height:1500px;width: 50px;position: absolute;"></div>
</div>

<div class="task-details mt-3">
  <div class="mt-3">
    <h3>Пространства проектов</h3>
    <small>Пространство проекта создается автоматически. Для создания нового пространства проекта, Вам нужно создать проект</small>
  </div>

  <div class="card">
    <p-dataView #dv [value]="workspaces$.value"
                [paginator]="isPaginator"
                [rows]="10"
                layout="list"
                emptyMessage="Проектов еще не создано...">
      <ng-template let-ws pTemplate="list">
        <div class="grid grid-nogutter">
          <div class="col-12 data-line" *ngFor="let w of ws">
              <h5 class="workspace-project-name py-3 m-0" (click)="onRouteWorkSpaceAsync(w.projectId, w.companyId)">{{w.projectManagementName}}</h5>

          </div>
        </div>
      </ng-template>
    </p-dataView>
  </div>
</div>

<left-panel
  (mouseleave)="onClosePanelMenu()"
></left-panel>
