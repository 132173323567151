<p-toast></p-toast>

<div class="d-flex block-profile space-top-80">
  <div class="profile-left-menu col-lg-3">
  </div>

  <div>
    <div>
        <order-form-steps></order-form-steps>
    </div>

    <div class="fare-rule-details mt-5">
        <p-panel header="Услуги">
          <div class="mt-3">
            <strong>План подписки:</strong>
          <div class="mt-1">
            <p>Количество месяцев: {{orderForm.month}}</p>
          </div>

          <div class="mt-3" *ngIf="orderForm.price > 0 && orderForm.month > 1">
            <strong>Цена со скидкой:</strong>
            <p>{{orderForm.price > 0 ? orderForm.price + ' ₽' : ''}}</p>

            <strong>Скидка за оплату сразу нескольких месяцев:</strong>
            <p>{{orderForm.price > 0 ? orderForm.percent + '%' : ''}}</p>
          </div>

          <div class="mt-3" *ngIf="disableDiscount">
            <strong>Цена:</strong>
            <p>{{orderForm.price + ' ₽'}}</p>
            <div *ngIf="paymentMonth == 1">
              <small>При оплате на месяц скидка не предоставляется (но учитывается остаток по тарифу).</small>
            </div>
          </div>          
          </div>
        </p-panel>

        <div class="mt-5">
          <p-panel header="Примененные к услугам сервисы" class="">
            <div class="" *ngIf="orderForm.products.length">
              <strong>Сервисы:</strong>
              <div *ngFor="let s of orderForm.products">
                <p>{{s + '%'}}</p>
              </div>
            </div>
          </p-panel>
        </div>

        <div class="d-flex worksheet-line mt-3">
            <div class="control-input-text-worksheet control-order-form">
              <button type="button" 
                      class="btn btn-primary button-action-pur-control"
                      (click)="onRouteNextStep()">
                К оплате
              </button>
            </div>
          </div>
    </div>
  </div>
</div>
