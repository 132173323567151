<p-toast></p-toast>

<div class="d-flex block-profile space-top-80">
  <div class="profile-left-menu col-lg-3">

  </div>

  <div class="block-worksheet">
    <div class="worksheet-panel">
      <p-panel header="Заполнение информации о проекте" [toggleable]="true">
        <div class="d-flex">
          <div class="control-input-text-worksheet">
            <div>
                <label class="create-project-required-field">Название проекта</label>
            </div>
            <div>
                <input type="text" class="p-inputtext-sm input-control-worksheet control-project"
                placeholder="Название проекта" pInputText
              [(ngModel)]="projectName"/>
            </div>
          </div>
        </div>

        <div class="d-flex mt-3">
            <div class="control-input-text-worksheet">
                <div class="control-input-text-worksheet">
                    <div class="d-flex worksheet-line">
                        <div class="control-input-text-worksheet">
                            <div>
                                <label class="create-project-required-field">Описание проекта</label>
                            </div>
                            <div class="control-input-text-worksheet">
                                <p-editor [(ngModel)]="projectDetails" [style]="{'height':'320px'}"></p-editor>
                            </div>
                        </div>
                    </div>
                  </div>
              </div>
        </div>

        <div class="d-flex mt-3">
          <div class="control-input-text-worksheet">
            <div>
                <label class="create-project-required-field">Стадия проекта</label>
            </div>
            <div>
              <p-dropdown [options]="this.projectStages$.value"
                          [(ngModel)]="selectedStage"
                          optionLabel="stageName"
                          appendTo="body"
                          class="control-project-stages"
                          (onChange)="onSelectProjectStage()">
              </p-dropdown>
            </div>
          </div>
        </div>

        <div class="d-flex mt-3">
          <div class="control-input-text-worksheet">
            <div>
                <strong>Публичный проект</strong>
            </div>
            <div class="d-flex align-items-center gap-2">
              <p-inputSwitch
                  [(ngModel)]="public">
              </p-inputSwitch>

              <small 
                pTooltip="Включенный режим - публичный проект.
                  Выключенный режим - приватный проект.
                  Публичные проекты отображаются в каталоге проектов, приватные - нет"
                >
                <i class="pi pi-exclamation-circle"></i>
              </small>
            </div>
          </div>
        </div>

        <div class="d-flex mt-3">
          <div class="control-input-text-worksheet">
            <div class="control-input-text-worksheet">
              <div class="d-flex worksheet-line">
                <div class="control-input-text-worksheet">
                  <div>
                    <label>Условия</label>
                  </div>
                  <div class="control-input-text-worksheet">
                    <p-editor [(ngModel)]="conditions" [style]="{'height':'320px'}"></p-editor>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex mt-3">
          <div class="control-input-text-worksheet">
            <div class="control-input-text-worksheet">
              <div class="d-flex worksheet-line">
                <div class="control-input-text-worksheet">
                  <div>
                    <label>Требования</label>
                  </div>
                  <div class="control-input-text-worksheet">
                    <p-editor [(ngModel)]="demands" [style]="{'height':'320px'}"></p-editor>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </p-panel>
    </div>

    <div class="worksheet-panel">
      <div class="d-flex worksheet-line mt-3">
        <div class="control-input-text-worksheet">
          <button type="button" class="btn btn-primary button-action-pur-control-worksheet"
            (click)="onCreateProjectAsync()">
            Сохранить
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
