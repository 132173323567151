<p-toast></p-toast>

<div class="section-1-container section-container">
    <div class="container">
      <div class="row">
        <div class="col-10 offset-1 col-lg-8 offset-lg-2 div-wrapper d-flex justify-content-center align-items-center">
          <div class="div-to-align">
            <div class="row justify-content-center form-restore">
              <h3 class="text-center title-text">Восстановление пароля</h3>

              <div class="mb-3" *ngIf="!isVisibleRestorePassword && !isVisibleCheckCode">
                <label for="exampleInputEmail1" class="form-label">Email</label>
                <input type="email" 
                        class="input-control" 
                        aria-describedby="emailHelp"
                        [(ngModel)]="email">
              </div>

              <div class="mb-3" *ngIf="isVisibleCheckCode">
                <label for="exampleInputPassword1">Проверочный код</label><br>
                <input type="password" 
                        class="input-control" 
                        autocomplete="new-password"
                        [(ngModel)]="confirmCode">
              </div>

              <div class="mb-3" *ngIf="isVisibleRestorePassword">
                <label for="exampleInputPassword1">Новый пароль</label><br>
                <input type="password" 
                        class="input-control" 
                        autocomplete="new-password"
                        [(ngModel)]="restorePassword">
              </div>

              <button type="submit" 
                      class="btn btn-primary button-action-pur-control-restore" 
                      (click)="onRestorePasswordAsync()">
                      {{restoreTxt}}
              </button>              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>