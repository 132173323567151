<div class="chat-ai">
  <p-button icon="pi pi-question-circle"
            styleClass="p-button-primary p-button-text"
            (click)="onShowScrumMasterAiAssist()">
  </p-button>
</div>

<div>
  <p-sidebar [(visible)]="isShowScrumMasterAiAssistModal" [fullScreen]="true">
    <div>
      <h3>Задайте вопрос - наш ИИ постарается Вам помочь.</h3>
    </div>

    <!--  Чат с ИИ.  -->
    <div class="worksheet-panel d-flex">
      <div class="d-flex">
        <div class="control-input-text-worksheet">
          <div class="">
            <div class="d-flex">
             <div>
               <p-splitter [style]="{'height': '100vh'}"
                           [panelSizes]="[20,80]"
                           [minSizes]="[10,0]"
                           styleClass="mb-5"
                           class="chat">
                 <!-- <ng-template pTemplate>
                     <div class="col flex align-items-center justify-content-center">
                         {{userName}}
                     </div>
                 </ng-template> -->
                 <ng-template pTemplate>
                   <p-splitter layout="vertical" [panelSizes]="[15,85]">
                     <!-- <ng-template pTemplate>
                         <div class="col flex align-items-center justify-content-center">

                         </div>
                     </ng-template> -->
                     <ng-template pTemplate>
                       <p-splitter [panelSizes]="[20,80]" [style]="{'width': '1000px', 'height': '100vh'}">
                         <!-- Левая область чата. -->
                         <ng-template pTemplate>
                           <div class="col flex align-items-center justify-content-center"
                                *ngFor="let item of aDialogs">
                             <div class="block-message" (click)="onGetDialogAsync(item.dialogId, false)">
                               <div class="d-flex justify-content-between">
                                 <div>
                                   <div>
                                     <strong>{{item.fullName}}</strong>
                                   </div>

                                   <div>
                                     <span class="message">{{item.lastMessage}}</span>
                                   </div>
                                 </div>

                                 <div>
                                   {{item.calcShortDate}}
                                 </div>
                               </div>
                             </div>
                           </div>
                         </ng-template>

                         <!-- Основная область чата. -->
                         <ng-template pTemplate>
                           <div id="#idMessages" class="block-messages">
                             <div *ngFor="let item of aMessages">
                               <div class="d-flex justify-content-between" [ngClass]="{blockMessage: item.isMyMessage}">
                                 <p>{{item.message}}</p>
                                 <span class="time-right">{{item.created}}</span>
                               </div>
                             </div>
                           </div>

                           <div>
                             <input type="text"
                                    class="p-inputtext-sm input-control-worksheet control-project"
                                    placeholder="Введите сообщение"
                                    pInputText
                                    autocomplete="new-password"
                                    [(ngModel)]="message"
                                    (keyup.enter)="onSendMessageAsync()"/>
                           </div>
                         </ng-template>
                       </p-splitter>
                     </ng-template>
                   </p-splitter>
                 </ng-template>
               </p-splitter>
             </div>

              <div class="col-lg-3">
                &nbsp;
                <p-button icon="pi pi-plus"
                          styleClass="p-button-success p-button-text"
                          label="Новый вопрос"
                          (click)="onGetDialogAsync(null, true)">
                </p-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </p-sidebar>
</div>
