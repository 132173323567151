<p-toast></p-toast>

<div class="d-flex block-profile space-top-80">
  <div class="profile-left-menu col-lg-3">
  </div>

  <div>
    <div>
      <order-form-steps></order-form-steps>
    </div>

    <div class="fare-rule-details mt-5">
      <p-panel header="Описание и условия тарифа">
        <div class="fare-rules-block mt-3">
          <div class="">
            <div *ngFor="let attr of fareRuleInfo$.value.fareRuleAttributes">
              <div class="">
                <div>
                  <strong>{{attr.attributeDetails}}</strong>
                </div>

                <div *ngIf="attr.attributeId == 1">
                  {{fareRuleInfo$.value.ruleName}}
                </div>
              </div>

              <div *ngFor="let attrValue of attr.fareRuleAttributeValues">
                <div
                  *ngIf="attrValue.attributeId == 2
                  || attrValue.attributeId == 4
                  || attrValue.attributeId == 5
                  || attrValue.attributeId == 6
                  || attrValue.attributeId == 7">
                  <div *ngIf="attrValue.attributeId == 2">
                    {{attrValue.measure}} {{attrValue.minValue ?? attrValue.content}}
                  </div>

                  <div *ngIf="attrValue.attributeId != 2">
                    {{attrValue.minValue}} {{attrValue.measure}}
                  </div>
                </div>

                <div *ngIf="attrValue.attributeId == 4 && attrValue.ruleId == 1">
                  {{attrValue.content}}
                </div>

                <div *ngIf="attrValue.attributeId == 1
          || attrValue.attributeId == 3
          || attrValue.attributeId == 5
          || attrValue.attributeId == 6
          || attrValue.attributeId == 7">
                  <div>
                    {{attrValue.contentTooltip}}
                  </div>

                  <div>
                    {{attrValue.content}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </p-panel>

      <div class="d-flex worksheet-line mt-3">
        <div class="control-input-text-worksheet control-order-form">
          <button type="button"
                  class="btn btn-primary button-action-pur-control"
                  (click)="onRouteNextStepAsync()">
            К настройкам тарифного плана
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
