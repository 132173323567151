<p-toast></p-toast>

<div class="d-flex block-profile space-top-80">
  <div class="profile-left-menu col-lg-3">
  </div>

  <div>
    <div>
        <order-form-steps></order-form-steps>
    </div>

    <div class="fare-rule-details mt-5">
        <p-panel header="Настройка тарифного плана">
          <div class="mb-3">
            <strong>Настройте тарифный план (цена рассчитается автоматически)</strong>
          </div>

          <div>
            <div>
              <strong>Количество пользователей в организации </strong>
            </div>

            <div>
              <p-inputNumber
                inputId="employeeCount"
                [(ngModel)]="employeeCount"
                [min]="1"
                (onInput)="onCalculatePriceAsync()">
              </p-inputNumber>
            </div>

            <div>
              <small>Укажите количество пользователей в вашей организации.</small>
            </div>
          </div>

          <div class="mt-3">
            <div>
              <strong>Количество месяцев подписки </strong>
            </div>

            <div>
              <div *ngFor="let month of aMonthItems" class="field-checkbox">
                <div class="d-flex">
                  <div>
                    <p-radioButton
                      [inputId]="month"
                      name="month"
                      [value]="month"
                      [(ngModel)]="selectedMonth"
                      (click)="onCalculatePriceAsync()">
                    </p-radioButton>
                  </div>

                  <div class="month-label">
                    <label>
                      {{ month.name }}
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <small>Укажите количество месяцев, на которое будет оформлена подписка.</small>
            </div>
          </div>

          <div class="mt-3" *ngIf="isContinue">
            <strong>Цена:</strong>
            <div> {{calculatedPrice$.value?.price + ' ₽'}}</div>
          </div>
        </p-panel>

        <div class="d-flex worksheet-line mt-3">
            <div class="control-input-text-worksheet control-order-form">
              <button type="button"
                      class="btn btn-primary button-action-pur-control"
                      [disabled]="!isContinue"
                      (click)="onRouteNextStepAsync()">
                К оплате
              </button>
            </div>
          </div>
    </div>
  </div>
</div>
