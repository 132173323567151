<div class="mt-3 fare-rule-title">
  <h3>Тарифные планы</h3>
</div>

<div class="fare-rules-block mt-3">
  <div *ngFor="let fareRule of fareRules$.value">
    <div class="">
      <div *ngFor="let attr of fareRule.fareRuleAttributes">
        <div class="">
          <div>
            <strong>{{attr.attributeDetails}}</strong>
          </div>

          <div *ngIf="attr.attributeId == 1">
            {{fareRule.ruleName}}
          </div>
        </div>

        <div *ngFor="let attrValue of attr.fareRuleAttributeValues">
          <div
            *ngIf="attrValue.attributeId == 2
            || attrValue.attributeId == 4
            || attrValue.attributeId == 5
            || attrValue.attributeId == 6
            || attrValue.attributeId == 7">
            <div *ngIf="attrValue.attributeId == 2">
              {{attrValue.measure}} {{attrValue.minValue ?? attrValue.content}}
            </div>

            <div *ngIf="attrValue.attributeId != 2">
              {{attrValue.minValue}} {{attrValue.measure}}
            </div>
          </div>

          <div *ngIf="attrValue.attributeId == 4 && attrValue.ruleId == 1">
            {{attrValue.content}}
          </div>

          <div *ngIf="attrValue.attributeId == 1
          || attrValue.attributeId == 3
          || attrValue.attributeId == 5
          || attrValue.attributeId == 6
          || attrValue.attributeId == 7">
            <div>
              {{attrValue.contentTooltip}}
            </div>

            <div>
              {{attrValue.content}}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="">
      <div class="control-fare-rule">
        <button type="submit"
                class="btn btn-primary button-action-pur-control-fare-rule"
                (click)="onRouteOrderInfoAsync(fareRule.publicId)"
                [disabled]="!isAvailableFareRule"
                *ngIf="!fareRule.isFree">
          Оформить
        </button>

        <div class="d-flex justify-content-center" *ngIf="!isAvailableFareRule">
          <small>Для оформления тарифа нужно быть авторизованными.</small>
        </div>
      </div>
    </div>
  </div>
</div>
