<p-toast></p-toast>

<div class="d-flex block-profile space-top-50">
  <div class="profile-left-menu col-lg-3 col-xl-3 col-xxl-3">

  </div>

  <div class="worksheet-panel block-worksheet profile-table">
    <p-table [value]="userProjects$.value.userProjects"
            [(selection)]="selectedProjects"
            dataKey="projectId"
            [paginator]="userProjects$.value.total > 0"
            [rows]="10"
            styleClass="p-datatable-gridlines"
            responsiveLayout="scroll"
            (click)="onSelectProject()">
      <ng-template pTemplate="caption">
          <h3>Ваши проекты</h3>
      </ng-template>

      <ng-template pTemplate="header">
        <tr>
          <th></th>
          <th *ngFor="let item of projectColumns$.value">{{item.columnName}}</th>
          <th class="table-header-item">Просмотр</th>
          <th class="table-header-item">Изменить</th>
          <th class="table-header-item">Архив</th>
          <th class="table-header-item">Удалить</th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-project>
          <tr>
            <td>
              <p-tableCheckbox [value]="project"></p-tableCheckbox>
            </td>
            <td class="table-header-item">{{project.projectId}}</td>
            <td class="table-header-item">{{project.projectName}}</td>
            <td class="table-header-item" [innerHTML]="project.projectDetails"></td>
            <td class="table-header-item">{{project.projectStatusName}}</td>
            <td><button pButton type="button"
              label="Просмотр"
              class="p-button-outlined p-button-primary"
              (click)="onRouteViewProject(project.projectId)">
                </button>
            </td>
            <td><button pButton type="button"
                        label="Редактировать"
                        class="p-button-outlined p-button-primary"
                        (click)="onRouteEditProject(project.projectId)">
                </button>
            </td>

            <td>
              <button pButton type="button"
                      label="В архив"
                      class="p-button-outlined p-button-warning"
                      [disabled]="project.projectStatusSysName == 'Archived'"
                      (click)="onAddArchiveProjectAsync(project.projectId)">
              </button>
            </td>
            <td>
              <button pButton
                      type="button"
                      label="Удалить"
                      class="p-button-outlined p-button-danger"
                      (click)="onBeforeDeleteProject(project.projectId, project.projectName)">
              </button>
            </td>
          </tr>
      </ng-template>

      <ng-template pTemplate="summary">
          Всего: {{userProjects$.value.total}}
      </ng-template>

      <ng-template pTemplate="emptymessage">
        <tr>
            <td colspan="7">
              <div class="empty-projects">
                Вы не создали еще ни одного проекта.
              </div>
            </td>
        </tr>
    </ng-template>
  </p-table>
  </div>
</div>

<!-- Диалог удаления проекта. -->
<p-dialog header="Удаление проекта"
          [(visible)]="isDeleteProject"
          [breakpoints]="{'960px': '75vw'}"
          [style]="{width: '50vw'}"
          [draggable]="false"
          [resizable]="false">
  <p>Удалить проект <strong>{{projectName}}</strong>?</p>
        <p>При удалении проекта будут удалены привязанные к проекту вакансии, сообщения чата проекта, все участники команды проекта, комментарии к проекту, рабочее пространство проекта.</p>
        <ng-template pTemplate="footer">
        <p-button (click)="isDeleteProject=false" label="Отменить" styleClass="p-button-text"></p-button>
        <p-button (click)="onDeleteProjectAsync()" label="Удалить" styleClass="p-button-danger p-button-text"></p-button>
        </ng-template>
</p-dialog>
