/**
 * Класс входной модели отклонения анкеты модератором.
 */
export class RejectResumeInput {
  /**
   * Id проекта.
   */
  ProfileInfoId: number = 0;
}

