<p-toast></p-toast>

<div class="left-panel" (mouseover)="onSelectPanelMenu()">
  <div style="border-left:1px solid #C0C6CF;height:1500px;position: absolute;"></div>
</div>

<left-panel></left-panel>

<div class="">
  <div class="d-flex mt-3 mb-3 justify-content-between">
    <h3 class="block-sprint">Планирование спринта</h3>
  </div>

  <div>
    <hr>
  </div>

  <!-- Блок с кнопками действий. -->
  <div class="mt-3 mb-3 d-flex block-task-actions">
    <div>
      <button type="button"
              pButton
              icon="pi pi-save"
              label="Спланировать спринт"
              class="p-button-text p-button-success p-button-sm control-sprint-strong"
              pTooltip="Планирование спринта"
              tooltipPosition="bottom"
              (click)="onPlaningSprintAsync()">
      </button>
    </div>
  </div>


  <div>
    <hr>
  </div>

  <div class="d-flex mt-3 block-sprint">
    <strong>Основная информация о спринте</strong>
  </div>

<div class="d-flex">
  <div class="col-lg-7">
    <div class="block-worksheet">
      <div class="block-sprint">
        <div class="mt-3">
          <div>
            <label class="required">Название спринта</label>
          </div>

          <div>
            <input pInputText
                   type="text"
                   class="p-inputtext-sm"
                   placeholder="Название спринта"
                   [(ngModel)]="sprintName"
                   [style]="{'width':'80%'}"/>
          </div>
        </div>

        <div class="mt-3">
          <div>
            <label>Описание спринта (опционально)</label>
          </div>

          <div>
            <p-editor
              placeholder="Описание спринта"
              [(ngModel)]="sprintDescription"
            />
          </div>
        </div>
        <div class="task-ver-line">

        </div>
      </div>

      <div class="mt-3 block-sprint">
        <strong>Выбрать задачи для спринта</strong>
        <div>
          <p-checkbox inputId="sprintTasks"
                      [value]="isSprintTasks"
                      (onChange)="isSprintTasks = !isSprintTasks"
                      [binary]="true">

          </p-checkbox>
          <label for="sprintTasks">&nbsp;Добавить задачи в спринт</label>
        </div>
        <small *ngIf="!isSprintTasks">Если не выбраны задачи, то спринт будет спланирован без задач.
          <br>
          Их можно будет включить в спринт позже.
          <br>
          Спринт без задач нельзя будет начать.
        </small>
      </div>

      <div class="mt-3 block-sprint" *ngIf="isSprintTasks">
        <div>
          <strong>Задачи спринта</strong>
        </div>
      </div>

      <div class="mt-3 block-sprint" *ngIf="isSprintTasks">
       <div class="mb-3">
         <p-autoComplete [(ngModel)]="selectedTask"
                         [suggestions]="searchSprintTasks$.value"
                         (completeMethod)="onSearchIncludeSprintTaskAsync($event)"
                         [showEmptyMessage]="true"
                         emptyMessage="Результатов не найдено"
                         field="findText"
                         [minLength]="0"
                          placeholder="Добавить задачу в спринт"
                         (onSelect)="onSelectTask()"
                         [style]="{'width':'500px'}"
                         [inputStyle]="{'width':'100%'}"
                         [disabled]="!isSearchByTaskId && !isSearchByTaskName && !isSearchByTaskDescription"
         >

         </p-autoComplete>

         <div class="d-flex mt-1">
          <div>
            <p-checkbox inputId="searchByTaskId"
                        [value]="isSearchByTaskId"
                        [(ngModel)]="isSearchByTaskId"
                        (onChange)="setSearchType('isSearchByTaskId')"
                        [binary]="true">

            </p-checkbox>
            <label for="searchByTaskId">&nbsp;&nbsp;Искать по ID задачи</label>
          </div>

           &nbsp;

           <div>
             <p-checkbox inputId="searchByTaskName"
                         [value]="isSearchByTaskName"
                         [(ngModel)]="isSearchByTaskName"
                         (onChange)="setSearchType('isSearchByTaskName')"
                         [binary]="true">

             </p-checkbox>
             <label for="searchByTaskName">&nbsp;&nbsp;Искать в названии задачи</label>
           </div>

           &nbsp;

           <div>
             <p-checkbox inputId="sprintTasksDescription"
                         [value]="isSearchByTaskDescription"
                         [(ngModel)]="isSearchByTaskDescription"
                         (onChange)="setSearchType('isSearchByTaskDescription')"
                         [binary]="true">

             </p-checkbox>
             <label for="sprintTasksDescription">&nbsp;&nbsp;Искать в описании задачи</label>
           </div>
         </div>
       </div>

        <p-table [value]="aAddedTaskSprint"
                [paginator]="aAddedTaskSprint.length > 0"
                styleClass="p-datatable-sm"
                [tableStyle]="{'min-width': '50rem'}"
                [rows]="10"
                responsiveLayout="scroll"
                [totalRecords]="tableTotalRecords">
          <ng-template pTemplate="header">
            <tr>
              <th>Ключ</th>
              <th>Тип задачи</th>
              <th>Название задачи</th>
              <th>Статус</th>
              <th>Исполнитель</th>
              <th>Исключение из спринта</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-t>
            <tr>
              <td class="link">
                <div (click)="onSelectTaskLink(t?.projectTaskId)">{{t?.fullProjectTaskId}}</div>
              </td>

              <td>
                <div>{{t?.taskTypeName}}</div>
              </td>

              <td>
                <div>{{t?.name}}</div>
              </td>

              <td>
                <div class="smaller-text">
                  {{t?.statusName}}
                </div>
              </td>

              <td>
                <div class="smaller-text">
                  {{t?.executorName}}
                </div>
              </td>

              <td>
                <div class="cancel">
                  <button pButton type="button"
                          icon="pi pi-times"
                          class="p-button-danger p-button-sm p-button-text"
                          pTooltip="Исключить задачу из спринта"
                          tooltipPosition="bottom"
                          (click)="onRemoveAddedTask(t.projectTaskId)">
                  </button>
                </div>
              </td>
            </tr>
          </ng-template>

          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="7">
                <div>
                  Нет данных.
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>

  <div class="col-lg-4">
    <strong>Люди</strong>
    <div>
      <div class="line-item d-flex">
        <label class="control-label-task-item">Исполнитель:</label>
        <div>
          <p-dropdown [options]="taskPeople$.value"
                      [(ngModel)]="selectedExecutor"
                      optionLabel="fullName"
                      [showClear]="true"
                      placeholder="Выберите исполнителя"
                      [emptyMessage]="(taskPeople$ | async) === true ? 'Результатов не найдено' : 'Загрузка...'"
                      (onClick)="onGetSelectTaskPeopleAsync()">
          </p-dropdown>
          <button pButton
                  pRipple
                  label="Назначить меня"
                  class="p-button-link control-button-executor-me"
                  (click)="onSetMeExecutor()">
          </button>
        </div>
      </div>

      <div class="line-item d-flex">
        <label class="control-label-task-item">Автор:</label>
        <p>Заполняется автоматически.</p>
      </div>

      <div class="line-item d-flex">
        <label class="control-label-task-item">Наблюдатели:</label>
        <div>
          <p-dropdown [options]="taskPeople$.value"
                      [(ngModel)]="selectedWatcher"
                      optionLabel="fullName"
                      [showClear]="true"
                      placeholder="Выберите наблюдателя"
                      [emptyMessage]="(taskPeople$ | async) === true ? 'Результатов не найдено' : 'Загрузка...'"
                      (onClick)="onGetSelectTaskPeopleAsync()"
                      (onChange)="onSelectWachers()">
          </p-dropdown>
          <button pButton
                  pRipple
                  label="Добавить меня"
                  class="p-button-link control-button-executor-me"
                  (click)="onSetMeWatcher()">
          </button>

          <div *ngFor="let w of aSelectedWachers">
            <div class="wacher">
              <p-chip label="{{w.fullName}}" [removable]="true"></p-chip>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-5">
      <strong>Даты</strong>

      <div class="line-item d-flex">
        <label class="control-label-task-item">Создано:</label>
        <p>Заполняется автоматически.</p>
      </div>

      <div class="line-item d-flex">
        <label class="control-label-task-item">Обновлено:</label>
        <p>Заполняется автоматически.</p>
      </div>

      <div>
        <p-checkbox inputId="sprintDates"
                    [value]="isSprintDates"
                    (onChange)="isSprintDates = !isSprintDates"
                    [binary]="true">

        </p-checkbox>
        <label for="sprintDates">&nbsp;Указать даты спринта</label>

        <div class="mt-3" *ngIf="isSprintDates">
          <div class="line-item">
            <label class="control-label-task-item">Дата начала спринта:</label>
            <br>
            <p-calendar [(ngModel)]="dateStart"
                        dateFormat="dd.mm.yy"
                        [showTime]="true"
                        icon="pi pi-calendar"
                        [showIcon]="true"
                        [locale]="locale">

            </p-calendar>
          </div>

          <div class="line-item mt-1">
            <label class="control-label-task-item">Дата окончания спринта:</label>
            <br>
            <p-calendar [(ngModel)]="dateEnd"
                        icon="pi pi-calendar"
                        [showIcon]="true"
                        dateFormat="dd.mm.yy"
                        [showTime]="true"
                        [locale]="locale">

            </p-calendar>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
