<p-toast></p-toast>

<!--<div class="left-panel" (mouseover)="onSelectPanelMenu()">-->
<!--  <div style="border-left:1px solid #C0C6CF;height:1500px;width: 50px;position: absolute;"></div>-->
<!--</div>-->

<div class="d-flex">
  <div class="col-lg-2">
    <left-panel></left-panel>
  </div>

  <div class="col-lg-10">
    <div class="task-details">
      <!-- Блок с кнопками действий. -->
      <div class="mt-3 mb-3 d-flex block-task-actions">
        <div *ngIf="taskDetails$.value.taskTypeId == 4">
          <!--      <button type="button"-->
          <!--              pButton-->
          <!--              icon="pi pi-save"-->
          <!--              label="Сохранить"-->
          <!--              class="p-button-text p-button-success p-button-sm control-sprint-strong"-->
          <!--              pTooltip="Сохранить изменения эпика"-->
          <!--              tooltipPosition="bottom"-->
          <!--              (click)="onIncludeEpicTaskAsync()">-->
          <!--      </button>-->
        </div>

        <div>
          <button type="button"
                  pButton
                  icon="pi pi-arrow-down"
                  label="Возможные действия"
                  class="p-button-text p-button-primary p-button-sm task-actions"
                  pTooltip="Возможные действия над задачей"
                  tooltipPosition="right"
                  (click)="menu.toggle($event)">
          </button>
          <p-menu #menu [popup]="true" [model]="aAvailableActions"></p-menu>
        </div>
      </div>

      <div>
        <hr>
      </div>
      <!-- Конец блока с кнопками действий -->

      <!-- Название задачи. -->
      <div class="task-name">
        <p-inplace [style]="{'min-height':'33px'}" [active]="isActiveTaskName" (onActivate)="onActivateTaskName()">
          <ng-template pTemplate="display">
            <h1 class="task-name-title">{{taskName}}</h1>
          </ng-template>
          <ng-template pTemplate="content">
            <input class="input-name-task" type="text" [(ngModel)]="taskName" value="{{taskName}}" pInputText>
          </ng-template>
        </p-inplace>

        <button pButton
                pRipple
                icon="pi pi-save"
                type="button"
                label="Сохранить"
                class="p-button-success p-button-text p-button-sm"
                *ngIf="isActiveTaskName"
                (click)="onSaveTaskNameAsync(taskName)">
        </button>

        <button pButton
                pRipple
                icon="pi pi-times"
                type="button"
                label="Отмена"
                class="p-button-secondary p-button-text p-button-sm"
                *ngIf="isActiveTaskName"
                (click)="isActiveTaskName=!isActiveTaskName">
        </button>
      </div>

      <div>
        <hr>
      </div>

      <!-- Детали задачи. -->
      <div class="block-task-lines col-lg-12 d-flex">
        <div class="col-lg-4">
          <strong>Детали задачи</strong>
          <div class="d-flex line">
            <div class="line-item d-flex">
              <label class="control-label-task-item">Тип:</label>
              <p class="line-item-value">{{taskDetails$.value?.taskTypeName}}</p>
            </div>
          </div>

          <form class="d-flex line"
                [formGroup]="formPriorities"
                *ngIf="taskDetails$.value.taskTypeId == 1 || taskDetails$.value.taskTypeId == 3">
            <label class="control-label-task-item">Приоритет:</label>

            <p-dropdown [options]="priorities$.value"
                        [(ngModel)]="selectedPriority"
                        optionLabel="priorityName"
                        [showClear]="true"
                        placeholder="Выберите приоритет"
                        [emptyMessage]="(priorities$ | async) === true ? 'Результатов не найдено' : 'Загрузка...'"
                        formControlName="priorityName"
                        (onChange)="onChangeTaskPriorityAsync()">
            </p-dropdown>
          </form>

          <div class="d-flex line">
            <label class="control-label-task-item">Метки:</label>

            <div class="chip-block">
              <div *ngFor="let lbl of taskDetails$.value?.tagNames">
                <div class="chip-item">
                  <p-chip label="{{lbl ?? 'Нет'}}" [removable]="true" (onRemove)="onDetachTaskTagAsync(lbl)"></p-chip>
                </div>
              </div>
            </div>

            <p-overlayPanel #op [style]="{'min-width': '300px'}">
              <ng-template pTemplate>
                <div>
                  <p-dropdown [options]="this.projectTags$.value"
                              [(ngModel)]="selectedTag"
                              optionLabel="tagName"
                              class="control-project-stages"
                              placeholder="Выберите тег"
                              (onChange)="onAttachTaskTagAsync()">
                  </p-dropdown>
                </div>
              </ng-template>
            </p-overlayPanel>

            <button pButton
                    pRipple
                    icon="pi pi-plus"
                    type="button"
                    class="p-button-secondary p-button-text p-button-sm"
                    (click)="op.toggle($event)">
            </button>
          </div>

          <div
            class="col-lg-4 mt-3"
            *ngIf="taskDetails$.value.taskTypeId == 1
            || taskDetails$.value.taskTypeId == 2
            || taskDetails$.value.taskTypeId == 3
            || taskDetails$.value.taskTypeId == 4"
          >
            <strong>Agile</strong>
            <form
              *ngIf="taskDetails$.value.taskTypeId != 4"
              class="d-flex line"
              [formGroup]="formEpic"
            >
              <label class="control-label-task-item">Эпик:</label>

              <div>
                <p-dropdown [options]="availableEpics$.value"
                            optionLabel="epicName"
                            class="project-epic-select"
                            placeholder="Добавить задачу в эпик"
                            [emptyMessage]="(availableEpics$ | async) === true ? 'Результатов не найдено' : 'Загрузка...'"
                            (onChange)="onChangeAvailableEpicsAsync()"
                            formControlName="epicName">
                </p-dropdown>
                <p-button label="Перейти к эпику" [text]="true" (click)="onRouteEpic()" [disabled]="!epicName" />
              </div>
            </form>

            <form class="d-flex line" [formGroup]="formSprint">
              <label class="control-label-task-item">Спринт:</label>

              <div>
                <p-dropdown [options]="sprintTask$.value"
                            [(ngModel)]="selectedSprint"
                            optionLabel="sprintName"
                            class="control-project-stages"
                            placeholder="Добавить задачу в спринт"
                            [emptyMessage]="(sprintTask$ | async) === true ? 'Результатов не найдено' : 'Загрузка...'"
                            (onChange)="onChangeAvailableSprintsAsync(selectedSprint.sprintId)"
                            formControlName="sprintName">
                </p-dropdown>
                <p-button label="Перейти к спринту" [text]="true" (click)="onRouteSprint()" [disabled]="!selectedSprint" />
              </div>
            </form>
          </div>
        </div>

        <form class="col-lg-4" [formGroup]="formStatuses">
          <div class="line-item d-flex">
            <label class="control-label-task-item">Статус:</label>
            <p-dropdown [options]="availableTransitions$.value"
                        [(ngModel)]="selectedStatus"
                        optionLabel="statusName"
                        [showClear]="true"
                        placeholder="Выберите статус"
                        [emptyMessage]="(availableTransitions$ | async) === true ? 'Результатов не найдено' : 'Загрузка...'"
                        formControlName="statusName"
                        (onChange)="onChangeStatusAsync()">
            </p-dropdown>
          </div>
        </form>

        <div class="col-lg-4">
          <strong>Люди</strong>
          <div>
            <div class="line-item d-flex">
              <label class="control-label-task-item">Автор:</label>
              <p class="line-item-value">{{taskDetails$.value?.authorName}}</p>
            </div>

            <form class="line-item d-flex" [formGroup]="formExecutors">
              <label class="control-label-task-item">Исполнитель:</label>
              <div>
                <p-dropdown [options]="taskPeople$.value"
                            [(ngModel)]="selectedExecutor"
                            optionLabel="fullName"
                            [showClear]="true"
                            placeholder="Выберите исполнителя"
                            [emptyMessage]="(taskPeople$ | async) === true ? 'Результатов не найдено' : 'Загрузка...'"
                            formControlName="executorName"
                            (onChange)="onChangeTaskExecutorAsync()">
                </p-dropdown>
                <!--            <button pButton pRipple label="Назначить меня" class="p-button-link control-button-executor-me"></button>-->
              </div>
            </form>

            <div class="line-item d-flex">
              <div>
                <label class="control-label-task-item">Наблюдатели:</label>
              </div>

              <div>
                <div class="chip-block">
                  <div *ngFor="let lbl of taskDetails$.value?.watcherNames; index as i">
                    <div class="chip-item">
                      <p-chip label="{{lbl ?? 'Нет'}}" [removable]="true" (onRemove)="onDetachTaskWatcherAsync(i)"></p-chip>
                    </div>
                  </div>
                </div>

                <p-overlayPanel #wa [style]="{'min-width': '300px'}">
                  <ng-template pTemplate>
                    <div>
                      <p-dropdown [options]="taskPeople$.value"
                                  [(ngModel)]="selectedWatcher"
                                  optionLabel="fullName"
                                  class="control-project-stages"
                                  placeholder="Выберите наблюдателя"
                                  [emptyMessage]="(taskPeople$ | async) === true ? 'Результатов не найдено' : 'Загрузка...'"
                                  (onChange)="onAttachTaskWatcherAsync()">
                      </p-dropdown>
                    </div>
                  </ng-template>
                </p-overlayPanel>

                <button pButton
                        pRipple
                        icon="pi pi-plus"
                        type="button"
                        class="p-button-secondary p-button-text p-button-sm"
                        (click)="wa.toggle($event)">
                </button>
                <!-- <button pButton pRipple label="Добавить меня" class="p-button-link control-button-executor-me"></button> -->
              </div>
            </div>
          </div>

          <div class="mt-3">
            <strong>Даты</strong>
            <div>
              <div class="line-item d-flex">
                <label class="control-label-task-item">Создано:</label>
                <p class="line-item-value">{{taskDetails$.value?.created}}</p>
              </div>

              <div class="line-item d-flex">
                <label class="control-label-task-item">Обновлено:</label>
                <p class="line-item-value">{{taskDetails$.value?.updated}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <hr>
      </div>

      <!-- Описание задачи. -->
      <div class="block-task-lines col-lg-12 d-flex">
        <div class="col-lg-8">
          <div>
            <strong>Описание</strong>
          </div>

          <p-inplace [style]="{'min-height':'30px'}" [active]="isActiveTaskDetails" (onActivate)="onActivateTaskDetails()">
            <ng-template pTemplate="display">
              <p-editor
                placeholder="Добавьте описание задачи, чтобы исполнителю было понятно, что нужно сделать в рамках задачи"
                [(ngModel)]="taskDetails"
                [readonly]="true"
                class="headless-editor frameless"
              />
            </ng-template>
            <ng-template pTemplate="content">
              <p-editor
                placeholder="Описание задачи"
                [(ngModel)]="taskDetails"
              />
              <div>
                <button pButton
                        pRipple
                        icon="pi pi-check"
                        type="button"
                        label="Сохранить"
                        class="p-button-success p-button-text p-button-sm"
                        *ngIf="isActiveTaskDetails"
                        (click)="onSaveTaskDetailsAsync()">
                </button>
                <button pButton
                        pRipple
                        icon="pi pi-times"
                        type="button"
                        label="Отмена"
                        class="p-button-secondary p-button-text p-button-sm"
                        *ngIf="isActiveTaskDetails"
                        (click)="isActiveTaskDetails=!isActiveTaskDetails">
                </button>
              </div>
            </ng-template>
          </p-inplace>
        </div>

        <div class="col-lg-4">

        </div>
      </div>

      <div>
        <hr>
      </div>

      <!-- Связанные задачи (обычные связи). -->
      <div class="block-task-lines d-flex" *ngIf="taskDetails$.value.taskTypeId == 1 || taskDetails$.value.taskTypeId == 2 || taskDetails$.value.taskTypeId == 3">
        <div class="">
          <div class="d-flex justify-content-between">
            <div class="space-bottom-10">
              <strong>Связанные задачи</strong>
            </div>

          </div>
          <div class="">
            <p-table [value]="taskLinkDefault$.value"
                     styleClass="p-datatable-sm"
                     [tableStyle]="{'min-width': '50rem'}"
                     [rows]="10"
                     responsiveLayout="scroll"
                     [paginator]="taskLinkDefault$.value?.length > 0">
              <ng-template pTemplate="header">
                <tr>
                  <th>Ключ</th>
                  <th>Название задачи</th>
                  <th>Статус</th>
                  <th>Исполнитель</th>
                  <th>Обновлено</th>
                  <th>Разорвать связь</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-t>
                <tr>
                  <td class="link">
                    <div (click)="onSelectTaskLink(t.projectTaskId)">{{t.fullTaskId ?? 'Не указан'}}</div>
                  </td>

                  <td>
                    <div>{{t.taskName}}</div>
                  </td>

                  <td>
                    <div class="smaller-text">
                      {{t.taskStatusName}}
                    </div>
                  </td>

                  <td>
                    <div class="smaller-text">
                      {{t.executorName}}
                    </div>
                  </td>

                  <td>
                    <div class="smaller-text">
                      {{t.lastUpdated}}
                    </div>
                  </td>

                  <td>
                    <div class="cancel">
                      <button pButton type="button"
                              icon="pi pi-times"
                              class="p-button-danger p-button-sm p-button-text"
                              (click)="onRemoveTaskLinkAsync(t.taskId, 'Link')"
                              pTooltip="Удалить связь"
                              tooltipPosition="bottom">
                      </button>
                    </div>
                  </td>
                </tr>
              </ng-template>

              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="7">
                    <div>
                      Связей нет.
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>

      <div *ngIf="taskDetails$.value.taskTypeId == 1 || taskDetails$.value.taskTypeId == 2 || taskDetails$.value.taskTypeId == 3">
        <hr>
      </div>

      <!-- Связанные задачи (родительские связи). -->
      <div class="block-task-lines d-flex" *ngIf="taskDetails$.value.taskTypeId == 1 || taskDetails$.value.taskTypeId == 2 || taskDetails$.value.taskTypeId == 3">
        <div class="">
          <div class="d-flex justify-content-between">
            <div class="space-bottom-10">
              <strong>Родительские задачи</strong>
            </div>

          </div>
          <div class="">
            <p-table [value]="taskLinkParent$.value"
                     styleClass="p-datatable-sm"
                     [tableStyle]="{'min-width': '50rem'}"
                     [rows]="10"
                     responsiveLayout="scroll"
                     [paginator]="taskLinkParent$.value?.length > 0">
              <ng-template pTemplate="header">
                <tr>
                  <th>Ключ</th>
                  <th>Название задачи</th>
                  <th>Статус</th>
                  <th>Исполнитель</th>
                  <th>Обновлено</th>
                  <th>Разорвать связь</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-t>
                <tr>
                  <td class="link" (click)="onSelectTaskLink(t.projectTaskId)">{{t.fullTaskId ?? 'Не указан'}}</td>
                  <td>
                    <div class="smaller-text">{{t.taskName}}</div>
                  </td>

                  <td>
                    <div class="smaller-text">
                      {{t.taskStatusName}}
                    </div>
                  </td>

                  <td>
                    <div class="smaller-text">
                      {{t.executorName}}
                    </div>
                  </td>

                  <td>
                    <div class="smaller-text">
                      {{t.lastUpdated}}
                    </div>
                  </td>

                  <td>
                    <div class="cancel">
                      <button pButton
                              type="button"
                              icon="pi pi-times"
                              class="p-button-danger p-button-sm p-button-text"
                              (click)="onRemoveTaskLinkAsync(t.taskId, 'Parent')">
                      </button>
                    </div>
                  </td>
                </tr>
              </ng-template>

              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="7">
                    <div>
                      Связей нет.
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>

      <div *ngIf="taskDetails$.value.taskTypeId == 1 || taskDetails$.value.taskTypeId == 2 || taskDetails$.value.taskTypeId == 3">
        <hr>
      </div>

      <!-- Связанные задачи (дочерние связи). -->
      <div class="block-task-lines d-flex" *ngIf="taskDetails$.value.taskTypeId == 1 || taskDetails$.value.taskTypeId == 2 || taskDetails$.value.taskTypeId == 3">
        <div class="">
          <div class="d-flex justify-content-between">
            <div class="space-bottom-10">
              <strong>Дочерние задачи</strong>
            </div>

          </div>
          <div class="">
            <p-table [value]="taskLinkChild$.value"
                     styleClass="p-datatable-sm"
                     [tableStyle]="{'min-width': '50rem'}"
                     [rows]="10"
                     responsiveLayout="scroll"
                     [paginator]="taskLinkChild$.value?.length > 0">
              <ng-template pTemplate="header">
                <tr>
                  <th>Ключ</th>
                  <th>Название задачи</th>
                  <th>Статус</th>
                  <th>Исполнитель</th>
                  <th>Обновлено</th>
                  <th>Разорвать связь</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-t>
                <tr>
                  <td class="link" (click)="onSelectTaskLink(t.projectTaskId)">{{t.fullTaskId ?? 'Не указан'}}</td>
                  <td>
                    <div class="smaller-text">{{t.taskName}}</div>
                  </td>

                  <td>
                    <div class="smaller-text">
                      {{t.taskStatusName}}
                    </div>
                  </td>

                  <td>
                    <div class="smaller-text">
                      {{t.executorName}}
                    </div>
                  </td>

                  <td>
                    <div class="smaller-text">
                      {{t.lastUpdated}}
                    </div>
                  </td>

                  <td>
                    <div class="cancel">
                      <button pButton
                              type="button"
                              icon="pi pi-times"
                              class="p-button-danger p-button-sm p-button-text"
                              (click)="onRemoveTaskLinkAsync(t.taskId, 'Child')">
                      </button>
                    </div>
                  </td>
                </tr>
              </ng-template>

              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="7">
                    <div>
                      Связей нет.
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>

      <div *ngIf="taskDetails$.value.taskTypeId == 1 || taskDetails$.value.taskTypeId == 2 || taskDetails$.value.taskTypeId == 3">
        <hr>
      </div>

      <!-- Связанные задачи (связи зависит от). -->
      <div class="block-task-lines d-flex" *ngIf="taskDetails$.value.taskTypeId == 1 || taskDetails$.value.taskTypeId == 2 || taskDetails$.value.taskTypeId == 3">
        <div class="">
          <div class="d-flex justify-content-between">
            <div class="space-bottom-10">
              <strong>Зависит от</strong>
            </div>

          </div>
          <div class="">
            <p-table [value]="taskLinkDepend$.value"
                     styleClass="p-datatable-sm"
                     [tableStyle]="{'min-width': '50rem'}"
                     [rows]="10"
                     responsiveLayout="scroll"
                     [paginator]="taskLinkDepend$.value?.length > 0">
              <ng-template pTemplate="header">
                <tr>
                  <th>Ключ</th>
                  <th>Название задачи</th>
                  <th>Статус</th>
                  <th>Исполнитель</th>
                  <th>Обновлено</th>
                  <th>Разорвать связь</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-t>
                <tr>
                  <td class="link" (click)="onSelectTaskLink(t.projectTaskId)">{{t.fullTaskId ?? 'Не указан'}}</td>
                  <td>
                    <div class="smaller-text">{{t.taskName}}</div>
                  </td>

                  <td>
                    <div class="smaller-text">
                      {{t.taskStatusName}}
                    </div>
                  </td>

                  <td>
                    <div class="smaller-text">
                      {{t.executorName}}
                    </div>
                  </td>

                  <td>
                    <div class="smaller-text">
                      {{t.lastUpdated}}
                    </div>
                  </td>

                  <td>
                    <div class="cancel">
                      <button pButton
                              type="button"
                              icon="pi pi-times"
                              class="p-button-danger p-button-sm p-button-text"
                              (click)="onRemoveTaskLinkAsync(t.taskId, 'Depend')">
                      </button>
                    </div>
                  </td>
                </tr>
              </ng-template>

              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="7">
                    <div>
                      Связей нет.
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>

      <div *ngIf="taskDetails$.value.taskTypeId == 1 || taskDetails$.value.taskTypeId == 2 || taskDetails$.value.taskTypeId == 3">
        <hr>
      </div>

      <!-- Связанные задачи (связи блокирует). -->
      <div class="block-task-lines d-flex" *ngIf="taskDetails$.value.taskTypeId == 1 || taskDetails$.value.taskTypeId == 2 || taskDetails$.value.taskTypeId == 3">
        <div class="">
          <div class="d-flex justify-content-between">
            <div class="space-bottom-10">
              <strong>Блокирует</strong>
            </div>

          </div>
          <div class="">
            <p-table [value]="taskLinkBlocked$.value"
                     styleClass="p-datatable-sm"
                     [tableStyle]="{'min-width': '50rem'}"
                     [rows]="10"
                     responsiveLayout="scroll"
                     [paginator]="taskLinkBlocked$.value?.length > 0">
              <ng-template pTemplate="header">
                <tr>
                  <th>Ключ</th>
                  <th>Название задачи</th>
                  <th>Статус</th>
                  <th>Исполнитель</th>
                  <th>Обновлено</th>
                  <th>Разорвать связь</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-t>
                <tr>
                  <td class="link" (click)="onSelectTaskLink(t.projectTaskId)">{{t.fullTaskId ?? 'Не указан'}}</td>
                  <td>
                    <div class="smaller-text">{{t.taskName}}</div>
                  </td>

                  <td>
                    <div class="smaller-text">
                      {{t.taskStatusName}}
                    </div>
                  </td>

                  <td>
                    <div class="smaller-text">
                      {{t.executorName}}
                    </div>
                  </td>

                  <td>
                    <div class="smaller-text">
                      {{t.lastUpdated}}
                    </div>
                  </td>

                  <td>
                    <div class="cancel">
                      <button pButton type="button" icon="pi pi-times" class="p-button-danger p-button-sm p-button-text"></button>
                    </div>
                  </td>
                </tr>
              </ng-template>

              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="7">
                    <div>
                      Связей нет.
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>

      <div class="mt-3 block-sprint" *ngIf="taskDetails$.value.taskTypeId == 4">
        <div>
          <strong>Задачи эпика</strong>
        </div>
      </div>

      <div class="mt-3 block-sprint" *ngIf="taskDetails$.value.taskTypeId == 4">
        <div class="pb-3 search-task-wrapper">
          <p-autoComplete [(ngModel)]="selectedTask"
                          [suggestions]="epicTasks$.value"
                          (completeMethod)="onSearchIncludeEpicTaskAsync($event)"
                          [showEmptyMessage]="true"
                          [disabled]="!isSearchByTaskId && !isSearchByTaskName && !isSearchByTaskDescription"
                          emptyMessage="Результатов не найдено"
                          field="findText"
                          [minLength]="0"
                          placeholder="Включить задачу в эпик"
                          (onSelect)="onIncludeEpicTaskAsync()"
                          class="epic-task-search-input">

          </p-autoComplete>

          <div class="d-flex mt-1 px-3">
            <div>
              <p-checkbox inputId="searchByTaskId"
                          [value]="isSearchByTaskId"
                          (onChange)="isSearchByTaskId = !isSearchByTaskId"
                          [binary]="true">

              </p-checkbox>
              <label for="searchByTaskId">&nbsp;&nbsp;Искать по ID задачи</label>
            </div>

            &nbsp;

            <div>
              <p-checkbox inputId="searchByTaskName"
                          [value]="isSearchByTaskName"
                          (onChange)="isSearchByTaskName = !isSearchByTaskName"
                          [binary]="true">

              </p-checkbox>
              <label for="searchByTaskName">&nbsp;&nbsp;Искать в названии задачи</label>
            </div>

            &nbsp;

            <div>
              <p-checkbox inputId="sprintTasksDescription"
                          [value]="isSearchByTaskDescription"
                          (onChange)="isSearchByTaskDescription = !isSearchByTaskDescription"
                          [binary]="true">

              </p-checkbox>
              <label for="sprintTasksDescription">&nbsp;&nbsp;Искать в описании задачи</label>
            </div>
          </div>
        </div>

        <p-table [value]="allEpicTasks"
                 styleClass="p-datatable-sm"
                 [tableStyle]="{'min-width': '50rem'}"
                 [rows]="10"
                 responsiveLayout="scroll"
                 [paginator]="allEpicTasks.length > 0">
          <ng-template pTemplate="header">
            <tr>
              <th>Ключ</th>
              <th>Тип задачи</th>
              <th>Название задачи</th>
              <th>Статус</th>
              <th>Исполнитель</th>
              <th>Исключение из эпика</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-t>
            <tr>
              <td class="link">
                <div (click)="onSelectTaskLink(t.projectTaskId)">{{t.fullProjectTaskId}}</div>
              </td>

              <td>
                <div>{{t.taskTypeName}}</div>
              </td>

              <td>
                <div>{{t.name}}</div>
              </td>

              <td>
                <div class="smaller-text">
                  {{t.taskStatusName}}
                </div>
              </td>

              <td>
                <div class="smaller-text">
                  {{t.executor?.executorName}}
                </div>
              </td>

              <td>
                <div class="cancel">
                  <button pButton type="button"
                          icon="pi pi-times"
                          class="p-button-danger p-button-sm p-button-text"
                          pTooltip="Исключить задачу из эпика"
                          tooltipPosition="bottom"
                          (click)="onRemoveEpicTaskAsync(t)">
                  </button>
                </div>
              </td>
            </tr>
          </ng-template>

          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="7">
                <div>
                  Нет данных.
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>

      <div *ngIf="taskDetails$.value.taskTypeId == 1 || taskDetails$.value.taskTypeId == 2 || taskDetails$.value.taskTypeId == 4">
        <hr>
      </div>

      <!-- Файлы задачи. -->
      <div class="block-task-lines col-lg-12 d-flex">
        <div class="col-lg-8">
          <strong>Прикрепить файлы</strong>
          <p-fileUpload [multiple]="true"
                        (onSelect)="onSelectTaskFiles($event)"
                        (uploadHandler)="onUploadTaskFilesAsync()"
                        [customUpload]="true"
                        chooseLabel="Прикрепить файл"
                        uploadLabel="Сохранить"
                        cancelLabel="Отменить"
                        uploadIcon="pi pi-save">
          </p-fileUpload>
        </div>

        <div class="col-lg-4 block-attached-files" *ngIf="taskFiles$?.value?.length">
          <div class="card block-task-files mt-4">
            <strong>Файлы задачи</strong>
            <ul *ngFor="let tf of taskFiles$?.value">
              <li>
                <div class="d-flex">
                  <div>
                    {{tf.documentName}}
                  </div>

                  <div class="cancel">
                    <button pButton type="button"
                            icon="pi pi-cloud-download"
                            class="p-button-primary p-button-sm p-button-text"
                            pTooltip="Скачать файл"
                            tooltipPosition="bottom"
                            (click)="onDownloadFileAsync(tf.documentId, tf.documentName)">
                    </button>

                    <button pButton type="button"
                            icon="pi pi-times"
                            class="p-button-danger p-button-sm p-button-text"
                            pTooltip="Удалить файл"
                            tooltipPosition="bottom"
                            (click)="onRemoveTaskFileAsync(tf.mongoDocumentId)">
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div>
        <hr>
      </div>

      <!-- Комментарии задачи. -->
      <div class="block-task-lines col-lg-12 d-flex">
        <div class="col-lg-8">
          <p-tabView>
            <p-tabPanel header="Комментарии">
              @for (c of taskComments$.value; track c.commentId) {
              <div>
                <div>&nbsp;</div>

            <p-panel header="Добавлено: {{c.userName}}">
              <div class="">
                <div class="block-comments">
                  <p-inplace [style]="{'min-height':'33px'}"
                             [active]="c.isActiveTaskComment"
                             (onActivate)="c.isActiveTaskComment = !c.isActiveTaskComment">
                    <ng-template pTemplate="display">
                      <p-editor
                        name="c.commentId"
                        [(ngModel)]="c.comment"
                        [readonly]="true"
                        class="headless-editor frameless"
                      />
                    </ng-template>

                    <ng-template pTemplate="content">
                      <p-editor
                        name="e{c.commentId}"
                        placeholder="Текст комментария"
                        [(ngModel)]="c.comment"
                      />
                    </ng-template>
                  </p-inplace>

                  <button pButton type="button"
                          icon="pi pi-times"
                          class="p-button-danger p-button-sm p-button-text"
                          (click)="onDeleteTaskCommentAsync(c.commentId)"
                          pTooltip="Удалить комментарий"
                          tooltipPosition="bottom">
                  </button>
                </div>

                <button pButton
                        pRipple
                        icon="pi pi-save"
                        type="button"
                        label="Сохранить"
                        class="p-button-success p-button-text p-button-sm"
                        *ngIf="c.isActiveTaskComment"
                        (click)="onUpdateTaskCommentAsync(c.commentId, c.comment)">
                </button>

                <button pButton
                        pRipple
                        icon="pi pi-times"
                        type="button"
                        label="Отмена"
                        class="p-button-secondary p-button-text p-button-sm"
                        *ngIf="c.isActiveTaskComment"
                        (click)="c.isActiveTaskComment=!c.isActiveTaskComment">
                </button>
              </div>
            </p-panel>
          </div>
              }
              <!-- Оставить комментарий. -->
              <div class="mt-3">
                <p-card header="Оставить комментарий" [style]="{width: '100%'}">
                  <div>
                    <p-editor
                      name="comment"
                      (onInit)="onCommentInit($event)"
                      placeholder="Текст комментария"
                      [(ngModel)]="comment"
                      (onTextChange)="onCommentChange($event)"
                    />
                  </div>

                  <div class="control-comment mt-1">
                    <p-button *ngIf="isCommentExist"
                              icon="pi pi-arrow-up-right"
                              (click)="onCreateTaskCommentAsync()"
                              label="Отправить"
                              styleClass="p-button-success p-button-text"
                    />
                  </div>
                </p-card>
              </div>
            </p-tabPanel>

            <p-tabPanel header="История">

            </p-tabPanel>
          </p-tabView>
        </div>

        <div class="col-lg-4">

        </div>
      </div>
    </div>
  </div>
</div>

<!-- Диалог выбора типа связи и добавления связи с задачей. -->
<p-dialog header="Добавление связи" [(visible)]="isVisibleCreateTaskLink" [modal]="true" [style]="{width: '50vw', height: '50vw'}"
    [draggable]="false" [resizable]="false">
    <div class="">
      <div>
        <label class="control-label-task-item">Тип связи:</label>
      </div>

     <div>
      <p-dropdown [options]="linkTypes$.value"
                  [(ngModel)]="selectedLinkType"
                  [showClear]="true"
                  placeholder="Выберите тип связи"
                  [emptyMessage]="(linkTypes$ | async) === true ? 'Результатов не найдено' : 'Загрузка...'"
                  optionLabel="value"
                  (onClick)="onGetAvailableTaskLinkAsync()">
      </p-dropdown>
     </div>
    </div>

    <div class="" *ngIf="selectedLinkType">
      <div>
        <label class="control-label-task-item">Задача для связи:</label>
      </div>

     <div>
      <p-dropdown [options]="linkTasks$.value"
                  [(ngModel)]="selectedTaskLink"
                  optionLabel="taskName"
                  [filter]="true"
                  filterBy="filterText"
                  [showClear]="true"
                  placeholder="Выберите задачу для связи">
        <ng-template pTemplate="selectedItem">
            <div class="country-item country-item-value" *ngIf="selectedTaskLink">
                <div>{{selectedTaskLink.taskName}}</div>
            </div>
        </ng-template>

        <ng-template let-t pTemplate="item">
            <div class="country-item">
                <div>{{t.taskName}}</div>
            </div>
        </ng-template>
    </p-dropdown>

    <div>
      <small *ngIf="selectedLinkType.key == 'Link'">Укажите, с какой задачей нужно связать текущую (связь без иерархии).

      </small>
      <small *ngIf="selectedLinkType.key == 'Parent'">Укажите, какая задача является родительской для текущей (связь с
        иерархией).

      </small>
      <small *ngIf="selectedLinkType.key == 'Child'">Укажите, какая задача является дочерней для текущей (связь с
        иерархией).

      </small>
      <small *ngIf="selectedLinkType.key == 'Depend'">Укажите, от какой задачи зависит текущая (связь без иерархии).

      </small>
    </div>
     </div>
    </div>

        <ng-template pTemplate="footer">
          <p-button icon="pi pi-times"
                    (click)="isVisibleCreateTaskLink = false"
                    label="Отменить"
                    styleClass="p-button-danger p-button-sm p-button-text">

          </p-button>

          <p-button icon="pi pi-check"
                    (click)="onCreateTaskLinkAsync()"
                    label="Добавить связь"
                    styleClass="p-button-success p-button-sm p-button-text"
                    [disabled]="!selectedLinkType">

        </p-button>
        </ng-template>
</p-dialog>

<!-- Диалог запрета к разделу, если нет нужных ролей. -->
<p-dialog header="Нет доступа"
          [(visible)]="isNotRolesAccessModal"
          [breakpoints]="{'960px': '75vw'}"
          [style]="{width: '50vw'}"
          [draggable]="false"
          [resizable]="false">
  <p>У вас нет доступа к данной функции. Пожалуйста, обратитесь к своему администратору проекта.</p>
  <ng-template pTemplate="footer">
    <p-button (click)="isNotRolesAccessModal=!isNotRolesAccessModal" label="Понятно" styleClass="p-button-text"></p-button>
  </ng-template>
</p-dialog>

<!--<left-panel-->
<!--  (mouseleave)="onClosePanelMenu()"-->
<!--&gt;</left-panel>-->
