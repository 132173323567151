<div class="d-flex block-profile space-top-80">
  <div class="col-lg-3">

  </div>

  <div class="col-lg-6 text-content">
    <h3>Заявка #{{selectedTicket$.value.ticketId}} - {{selectedTicket$.value.ticketName}} ({{selectedTicket$.value.statusName}})</h3>
    <div>
      <div class="mt-4" *ngFor="let item of aMessages">
        <p-card header="" [ngClass]="{blockMessage: item.isMyMessage}">
          <div>
            <div class="block-message">
              <p>{{item.message}}</p>
              <span class="time-right">{{item.dateCreated}}</span>
            </div>
          </div>
        </p-card>
      </div>

      <div class="mt-4">
        <p-card header="">
          <textarea rows="5" 
                    pInputTextarea 
                    [autoResize]="true"
                    autocomplete="new-password" 
                    placeholder="Введите сообщение" 
                    [(ngModel)]="ticketMessage"
                    class="control-textarea textarea-ticket">
          </textarea>
          <div class="d-flex block-ticket-control">
            <button type="submit" 
                    class="btn btn-primary button-action-pur-short-control"
                    (click)="onCreateTicketMessageAsync()"
                    [disabled]="selectedTicket$.value.isDisableSendButton">
                  Отправить
            </button>

                &nbsp;&nbsp;&nbsp;

                <button pButton 
                        type="button" 
                        label="Закрыть тикет" 
                        class="button-action-pur-short-control p-button-danger p-button-text pl-3"
                        (click)="isCloseTicket = true"
                        *ngIf="!selectedTicket$.value.isDisableCloseTicketButton">                      
              </button>
          </div>
        </p-card>
      </div>
    </div>
  </div>

  <div class="col-lg-3">

  </div>
</div>

<!-- Диалог закрытия тикета. -->
<p-dialog header="Закрытие тикета"
          [(visible)]="isCloseTicket"
          [breakpoints]="{'960px': '75vw'}"
          [style]="{width: '50vw'}"
          [draggable]="false"
          [resizable]="false">
  <p>Закрыть тикет <strong>{{selectedTicketName}}</strong>?</p>
        <ng-template pTemplate="footer">
        <p-button (click)="isCloseTicket=false" label="Отменить" styleClass="p-button-text"></p-button>
        <p-button (click)="onCloseTicketAsyic()" label="Закрыть тикет" styleClass="p-button-danger p-button-text"></p-button>
        </ng-template>
</p-dialog>